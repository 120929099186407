import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import { onceTransitionEnd } from 'web-animation-club'
import * as basicScroll from 'basicscroll'
import { observeElementInViewport, isInViewport } from 'observe-element-in-viewport'

import getImageSrcSet from '../../helpers/get-image-srcset'

// const hasWindow = (typeof window !== 'undefined')


const SlideInWrapper = ({
    image,
    className = '',
    imageClassName = 'w-full h-full object-cover'
}) => {

    // console.log('image', image)

    const rootRef = useRef(null)
    const imageRef = useRef(null)

    useEffect(() => {
        let basicScroller = null

        if ('scrollers' !== window) {
            window.scrollers = []
        }

        if (!imageRef.current.classList.contains('setup-started')) {
            imageRef.current.classList.add('setup-started')

            // Build scroller instance
            basicScroller = basicScroll.create({
                elem: imageRef.current,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--transform-translate-y': {
                        from: '-50px',
                        to: '50px',
                        // timing: 'elasticOut' // quintInOut
                    }
                }
            })

            window.scrollers.push(basicScroller)
        }

        const onEnter = async () => {
            // On element enter viewort

            // Let the browser know we're about to animate
            imageRef.current.style.setProperty('will-change', 'transform')

            // Start scroll animation
            basicScroller.start()

            // console.log('basicScroller', basicScroller)

            // Turn off this observer
            // unobserve()
        }

        // If we're already in the viewport right now
        // then trigger onEnter
        if (isInViewport(rootRef.current)) onEnter()

        const onExit = () => {
            // On element exit viewort

            // If our scroller is an object
            // and probably an instance as
            // opposed to null or undefined
            if (basicScroller === 'object') {
                // Stop scroll animation
                basicScroller.stop()
            }

            // Clear will-change so the
            // browser knows we're done animating
            if (imageRef.current) imageRef.current.style.setProperty('will-change', null)
        }

        // The returned function, when called, stops tracking the target element in the
        // given viewport
        const unobserve = observeElementInViewport(rootRef.current, onEnter, onExit, {
            // set viewport
            // viewport,

            // decrease viewport top by 100px
            // similar to this, modRight, modBottom and modLeft exist
            // modTop: '-100px',

            // threshold tells us when to trigger the handlers.
            // a threshold of 90 means, trigger the inHandler when atleast 90%
            // of target is visible. It triggers the outHandler when the amount of
            // visible portion of the target falls below 90%.
            // If this array has more than one value, the lowest threshold is what
            // marks the target as having left the viewport
            threshold: [ 0, 110 ]
        })

        // Destroy/unMount
        return () => {
            console.log('Destroying Parralax Image')

            if (basicScroller !== null) {
                console.log('Destroying scroller')
                // Clean up basicScroller instance
                basicScroller.stop()
                basicScroller.destroy()

                // Clear basicScroller value
                basicScroller = null
            }

            // Clean up observer
            unobserve()
        }
    }, [
        rootRef,
    ])

  return (
    <div
        ref={rootRef}
        className={[
            'parallax-image overflow-hidden',
            className
        ].join(' ')}
    >
        <picture
            className={[
                'w-full'
            ].join(' ')}
        >
            <source
                sizes={getImageSrcSet(image, 'object').sizes}
                data-srcset={getImageSrcSet( image, 'webp' )}
                type="image/webp"
            />
            <source
                sizes={getImageSrcSet(image, 'object').sizes}
                data-srcset={getImageSrcSet( image )}
                type="image/jpeg"
            />
            <img
                ref={imageRef}
                data-src={image.url}
                alt={image.alt}
                className={[
                    'transform lazyload',
                    imageClassName
                ].join(' ')}
                style={{
                    '--transform-scale-y': '1.2',
                    '--transform-scale-x': '1.2'
                }}
            />
        </picture>
    </div>
  )
}

SlideInWrapper.propTypes = {
    image: PropTypes.object.isRequired,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
}

export default SlideInWrapper
