import React from 'react'

import FadeInWrapper from '@components/animation/fade-in-wrapper'

export default ({ innerHTML }) => {

    // const {
    //     heading,
    //     text,
    //     url,
    //     image,
    //     className,
    //     // blockId,
    //     // blockUniqueClass
    // } = block.attrs

    return (
        <section className='null-block container flex justify-center'>
            <FadeInWrapper
                baseDelay={1000}

                // Reduce threshold since these null blocks can be multiple times the viewports height
                threshold={[ 0 ]}
            >
                <div className='max-w-2xl py-16 whitespace-pre-wrap'
                    dangerouslySetInnerHTML={{ __html: innerHTML }}
                />
            </FadeInWrapper>
        </section>
    )
}
