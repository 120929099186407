import React from 'react'
import { graphql } from 'gatsby'
import WPGBlocks from 'react-gutenberg'
// import 'react-gutenberg/default.css'

import NullBlock from './types/null-block'

// Lazy Blocks
import VideoHeroFeature from './types/video-hero-feature'
import QuoteFeature from './types/quote-feature'
import MediaBlockFeature from './types/media-block-feature'
import ParallaxImage from './types/parallax-image'

// Wordpress Blocks
import Image from './types/image'
import Paragraph from './types/paragraph'
import Heading from './types/heading'
import List from './types/list'
import VideoEmbed from './types/video-embed'

const blockComponents = {
    // ACF BLocks
    // 'acf/quotes-hero': QuotesHero,
    // 'acf/motion-feature': MotionFeature,

    'null-block': NullBlock,

    // Lazy Blocks
    'lazyblock/video-hero-feature': VideoHeroFeature,
    'lazyblock/quote-feature': QuoteFeature,
    'lazyblock/media-block-feature': MediaBlockFeature,
    'lazyblock/parallax-image': ParallaxImage,

    // Wordpress Blocks
    'core/image': Image,
    'core/paragraph': Paragraph,
    'core/heading': Heading,
    'core/quote': Paragraph,
    'core/list': List,
    'core-embed/vimeo': VideoEmbed,
    'core-embed/youtube': VideoEmbed
}

const getCustomBlock = function (blockName) {

    // If block is null
    if (blockName === null) {
        return ({ innerHTML }) => (
            <section>
                { innerHTML }
            </section>
        )
    }

    // console.log('blockName', blockName)
    // If this block has a matching component then load that
    if (blockComponents.hasOwnProperty(blockName)) {

        const BlockComponent = blockComponents[blockName]

        return BlockComponent
    }

    // No custom block?
    // Then just use react-gutenberg respective block
    return null
}


const blockMapper = block => {
    // console.log('block', block)

    const hasEmptyName = (block.blockName === null)
    const hasContent = (block.innerHTML.trim().length !== 0)
    // const hasEmptyName = (block.blockName === null)
    // Rename block with empty names
    // to null-block so their data
    // still gets passed
    if (hasEmptyName && hasContent) {
        return {
            ...block,
            blockName: 'null-block'
        }
    }

    return block
}


export default ({ blocks }) => (
    <article className="wp-blocks wp-embed-responsive overflow-hidden">
        <WPGBlocks
            blocks={blocks.map(blockMapper)}
            mapToBlock={getCustomBlock}
        />
    </article>
)

// type
// align
// className
// backgroundImage

export const query = graphql`
    fragment BlockImageProperties on ImageSharpFluid {
        srcSet
        srcSetWebp
        sizes
        originalImg
    }
    fragment BlockBackgroundImages on wordpress__PAGEBlocksAttrsImage {
        alt
        caption
        link
        title
    }

    fragment BlockAttrs on wordpress__PAGEBlocksAttrs {
        data
        heading
        text
        body
        media
        url
        items
        options
        backgroundColor
        backgroundOptions

        type
        align
        className

        blockId
        blockUniqueClass
    }

    fragment Blocks on wordpress__PAGEBlocks {
        blockName
        innerHTML
    }



    fragment PostBlockAttrs on wordpress__POSTBlocksAttrs {
        data
        heading
        text
        body
        media
        url
        items
        options
        backgroundColor
        backgroundOptions

        type
        align
        className
    }

    fragment PostBlocks on wordpress__POSTBlocks {
        blockName
        innerHTML
    }



    fragment ProjectBlockBackgroundImages on wordpress__wp_projectBlocksAttrsImage {
        alt
        caption
        link
        title
    }

    fragment ProjectBlockAttrs on wordpress__wp_projectBlocksAttrs {
        data
        heading
        text
        body
        media
        url
        items
        options
        backgroundColor
        backgroundOptions

        type
        align
        className
    }

    fragment ProjectBlocks on wordpress__wp_projectBlocks {
        blockName
        innerHTML
    }
`
