import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { onceTransitionEnd } from 'web-animation-club'
import { observeElementInViewport } from 'observe-element-in-viewport'



const SlideInText = ({
    text,
    alignClass = '',
    baseDelay = 200,
    verticalOffset = '1.1em'
}) => {

    const words = Array.isArray(text) ? text : text.trim().split(' ')

    const rootRef = useRef(null)

    useEffect(() => {

        // console.log('document', document)

        // the returned function, when called, stops tracking the target element in the
        // given viewport
        const unobserve = observeElementInViewport(rootRef.current, async (_, unobserve, rootElem) => {
            // On element enter viewort

            // Let the browser know we're about to animate
            rootRef.current.style.setProperty('--word-will-change', 'transform')

            // Collect all of our words in a mappable array
            const wordElems = Array.from(rootElem.querySelectorAll('.word-wrapper'))

            // Animate word to 0
            rootRef.current.style.setProperty('--fade-in-offset', 0)

            // Wait for transitions to finish
            await Promise.all(wordElems.map(onceTransitionEnd))

            // Clear will-change
            rootRef.current.style.setProperty('--word-will-change', null)

            // Turn off this observer
            unobserve()
        }, () => {
            // On element exit viewort
        }, {
            // set viewport
            // viewport,

            // decrease viewport top by 100px
            // similar to this, modRight, modBottom and modLeft exist
            // modTop: '-100px',

            // threshold tells us when to trigger the handlers.
            // a threshold of 90 means, trigger the inHandler when atleast 90%
            // of target is visible. It triggers the outHandler when the amount of
            // visible portion of the target falls below 90%.
            // If this array has more than one value, the lowest threshold is what
            // marks the target as having left the viewport
            threshold: [ 70 ]
        })

        // Destroy/unMount
        return () => {
            // console.log('Destroying Slide in text')

            // Clean up observer
            unobserve()
        }
    }, [
        // rootRef,
    ])

  return (
    <div
        ref={rootRef}
        className={[
            'flex flex-wrap ',
            alignClass
        ].join(' ')}
    >
        {words.map((word, wordIndex) => (
            <span
                key={wordIndex}
                data-word-index={wordIndex}
                className='word-container overflow-hidden whitespace-pre-wrap'
            >
                <span
                    className='word-wrapper inline-block ease-transform transform'
                    style={{
                        '--transform-translate-y': `var(--fade-in-offset, ${verticalOffset})`,
                        transitionDelay: `${baseDelay + wordIndex * 100}ms`,
                        willChange: 'var(--word-will-change, auto)'
                    }}
                >
                    {word}{' '}
                </span>
            </span>
        ))}

    </div>
  )
}

SlideInText.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
}

export default SlideInText
