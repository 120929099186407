import React from 'react'

import fixWpContentUrls from '@helpers/fix-wp-content-urls'

import { getAlignClassName } from './paragraph'

export default (block) => {

    // console.log('block', block)

    const {
        align,
        className = '',
    } = block.attrs

    // Fix bad wp-content urls
    const innerHTML = fixWpContentUrls(block.innerHTML)

    return (
        <div className={[
            'wpg-block wpg-b_image',
            className || '',
            getAlignClassName(align)
        ].join(' ')}
            dangerouslySetInnerHTML={{ __html: innerHTML }}
        />
    )
}
