import React, { useRef, useEffect } from 'react'
import { Player, FileProvider, PlayerState } from '@vime-js/standard'

import { isInViewport } from 'observe-element-in-viewport'

import fixWpContentUrls from '../../../helpers/fix-wp-content-urls'
import SlideInText from '../../typography/slide-in-text'
import SlideInWrapper from '../../animation/slide-in-wrapper'

const onBlockVisible =  async function (hookElement, callback) {
    // console.log('Setting up block visiblility')

    // If our hook is visible right now
    // then fire and stop
    if (await isInViewport(hookElement)) {
        // hookElement.hasSetup = true/
        callback()
        return
    }

    // Otherwise setup an event listener
    // for when the element is unveiled
    hookElement.addEventListener('lazybeforeunveil', () => {
        callback()
    })
}

const setupVideo = async function ({
    videoRef,
    video
}) {
    // console.log('Setting up player')

    // console.log('video', video)

    // const videoUrlInstance = new URL(video.url)
    // const preconnectDomain = `${videoUrlInstance.protocol}//${videoUrlInstance.hostname}`

    // Mount
    const player = new Player({
        target: videoRef.current,
        // If you'd like to initialize any props on setup, you can do so here.
        props: {
            src: video.url,
            // May improve performance, but needs to be confirmed
            // preconnections: [ preconnectDomain ],
            muted: true,
            autoplay: true,
            loop: true,
            isControlsEnabled: false,
            aspectRatio: `${document.documentElement.clientWidth}:${document.documentElement.clientHeight}`,
            providers: [ FileProvider ]
        }
    })

    const { state } = player.getStore()


    // Manual autoplay
    if (player.autoplay) {
        let unsubscribeManualAutoplay
        await new Promise(resolve => {
            unsubscribeManualAutoplay = state.subscribe(newState => {
                if (newState !== PlayerState.CUED) return

                // console.log('CUED')

                if (player.canMutedAutoplay) {
                    // Trigger play
                    player.paused = false
                } else {
                    console.warn('Can\'t autoplay hero bg')
                }

                // console.log('canMutedAutoplay', player.canMutedAutoplay)

                resolve()
            })
        })

        unsubscribeManualAutoplay()
    }

    return player
}

export default (block) => {

    const {
        data,
        className,
        // blockId,
        // blockUniqueClass
    } = block.attrs

    const [{
        mainHeading,
        secondaryHeading,
        poster,
        video,
    }] = JSON.parse(decodeURIComponent(data))

    const rootRef = useRef(null)
    const bgVideoRef = useRef(null)

    const delay = {
        background: 0,
        content: 1500,
    }

    // Fix content urls
    video.url = fixWpContentUrls(video.url)

    // const [isOpen, setOpen] = useState(false)
    // const [showPoster, setShowPoster] = useState(true)

    useEffect(() => {

        let player = null

        onBlockVisible(rootRef.current, async () => {

            // console.log('bgVideoRef.current', bgVideoRef.current.classList)

            if (!bgVideoRef.current.classList.contains('is-setup')) {

                // Set an intermediate value so that
                // if our effect triggers again
                // we don't start a second setup
                bgVideoRef.current.classList.add('is-setup')

                // Setup player
                player = await setupVideo({
                    videoRef: bgVideoRef,
                    video
                })
            }
        })

        return () => {
            // console.log('Tearing down video hero block')

            if (player !== null) {
                // console.log('player', player)
                player.paused = true
                player.$destroy()
                player = null
            }
        }
    }, [
        rootRef,
        bgVideoRef,
        video
    ])

    return (
        <div
            ref={rootRef}
            className={[
                `video-hero-feature-block flex justify-center w-full lazyload`,
                className
            ].join(' ')}
            style={{
                minHeight: '75vh'
            }}
        >

            <div className='block-layers relative w-full h-screen flex justify-center items-center bg-white'>

                {(poster && (
                    <div
                        className='block-background-layer absolute inset-0 w-full h-full flex items-center justify-center pointer-events-none'
                    >
                        {/* <img
                            data-src={poster.url}
                            alt={poster.alt}
                            className={[
                                'transform lazyload'
                            ].join(' ')}
                        /> */}
                        <div
                            ref={bgVideoRef}
                            className='block-background-video video-cover w-full h-full'
                        />
                    </div>
                ))}

                <div className='block-content-layer w-full'>
                    <div className='relative w-full lg:w-7/12 flex items-center justify-start md:justify-end md:-px-0'>
                        <SlideInWrapper baseDelay={delay.content}>
                            <div className='shadow-xl md:shadow-none px-12 py-12 md:pr-24 bg-white md:mr-64 relative'>
                                <h1 className='mb-8 text-3xl md:text-4xl text-crimson text-bold uppercase font-bold tracking-wide leading-tight'>
                                    <SlideInText
                                        text={mainHeading}
                                        baseDelay={delay.content + 500}
                                    />
                                </h1>
                                <h2 className='text-lg md:text-2xl text-gray-500'>{ secondaryHeading }</h2>

                                <SlideInWrapper
                                    baseDelay={delay.content + 2000}
                                    className='absolute top-0 right-0 -mr-24'
                                >
                                    <span className='hidden md:block w-48 h-4 bg-crimson mt-56'></span>
                                </SlideInWrapper>
                            </div>
                        </SlideInWrapper>
                    </div>
                </div>

            </div>

        </div>
    )
}
