export default function ( rawString ) {
    // console.log('process.env.URL', process.env.URL)
    // console.log('process.env.WP_HOME', process.env.WP_HOME)

    const searchString = `${process.env.URL}/wp-content`
    const replacementString = `${process.env.WP_HOME}/wp-content`

    const fixedString = rawString.split(searchString).join(replacementString)

    // console.log('rawString', rawString)
    // console.log('cleanString', cleanString)

    return fixedString
}
