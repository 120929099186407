import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import { onceTransitionEnd } from 'web-animation-club'
import { observeElementInViewport } from 'observe-element-in-viewport'



const SlideInWrapper = ({
    children,
    baseDelay = 200,
    verticalOffset = '100%',
    className = ''
}) => {

    const rootRef = useRef(null)
    const slideInRef = useRef(null)

    useEffect(() => {

        // console.log('document', document)

        // the returned function, when called, stops tracking the target element in the
        // given viewport
        const unobserve = observeElementInViewport(rootRef.current, async (_, unobserve, rootElem) => {
            // On element enter viewort

            // Let the browser know we're about to animate
            // rootRef.current.style.setProperty('will-change', 'transform')

            // Animate word to 0
            rootElem.style.setProperty('--fade-in-offset', 0)

            // Wait for transitions to finish
            // await onceTransitionEnd(rootRef.current)

            // Clear will-change
            // rootRef.current.style.setProperty('will-change', null)

            // Turn off this observer
            unobserve()
        }, () => {
            // On element exit viewort
        }, {
            // set viewport
            // viewport,

            // decrease viewport top by 100px
            // similar to this, modRight, modBottom and modLeft exist
            // modTop: '-100px',

            // threshold tells us when to trigger the handlers.
            // a threshold of 90 means, trigger the inHandler when atleast 90%
            // of target is visible. It triggers the outHandler when the amount of
            // visible portion of the target falls below 90%.
            // If this array has more than one value, the lowest threshold is what
            // marks the target as having left the viewport
            threshold: [ 70 ]
        })

        // Destroy/unMount
        return () => {
            // console.log('Destroying Slide in wrapper')

            // Clean up observer
            unobserve()
        }

    }, [
        rootRef,
    ])

  return (
    <div
        ref={rootRef}
        className={[
            'slide-in-wrapper overflow-hidden',
            className
        ].join(' ')}
    >
        <div
            ref={slideInRef}
            className={[
                'slide-in transform ease-transform'
            ].join(' ')}
            style={{
                '--transform-translate-y': `var(--fade-in-offset, ${verticalOffset})`,
                transitionDelay: `${baseDelay}ms`,
            }}
        >
            { children }
        </div>
    </div>
  )
}

SlideInWrapper.propTypes = {
    baseDelay: PropTypes.number,
    verticalOffset: PropTypes.string,
    className: PropTypes.string,
}


export default SlideInWrapper
