import React from 'react'
import slugify from 'slugify'

import { getAlignClassName, blockDelayFormula } from './paragraph'
import FadeInWrapper from '@components/animation/fade-in-wrapper'

export default (block) => {

    // console.log('block', block)

    const {
        align,
        className = ''
    } = block.attrs

    const slug = slugify(block.innerHTML.replace(/<[^>]*>?/gm, ''), {
        lower: true
    })

    return (
        <section
            id={slug}
            className={[
                'wpg-block wpg-b_list',
                className || '',
                getAlignClassName(align, 'max-w-md w-full p-6 mb-4')
            ].join(' ')}
        >
            <FadeInWrapper
                baseDelay={blockDelayFormula(block)}
            >
                <div dangerouslySetInnerHTML={{ __html: block.innerHTML }} />
            </FadeInWrapper>
        </section>
    )
}
