import React, { useRef } from 'react'

import SlideInText from '../../typography/slide-in-text'


export default (block) => {

    const {
        heading,
        text,
        className,
        // blockId,
        // blockUniqueClass
    } = block.attrs

    const rootRef = useRef(null)

    return (
        <div
            ref={rootRef}
            className={[
                `quote-feature-block flex justify-center w-full lazyload`,
                className
            ].join(' ')}
        >

            <div className='block-layers relative w-full flex justify-center items-center bg-white'>

                <div className='block-content-layer w-full'>
                    <div className='bg-crimson px-12 py-24 flex items-center'>

                        <div className='hidden md:block w-3/12'></div>

                        <div className='flex-1 relative'>
                            <h2 className='mb-8 uppercase text-white text-2xl font-bold tracking-widest'>
                                <SlideInText text={heading} />
                            </h2>
                            <div className='text-gray-500 text-base md:text-xl max-w-2xl leading-loose'
                                dangerouslySetInnerHTML={{ __html: text }}
                            />

                            <span className='absolute top-0 left-0 w-4 h-full bg-gray-300 -ml-20'></span>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}
