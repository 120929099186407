import React, { Component } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Blocks from '../components/blocks'
import SlideInText from '../components/typography/slide-in-text'

class Page extends Component {
  render() {

    // console.log('this.props.data', this.props.data)

    const StaticPage = this.props.data.wordpressWpProject || this.props.data.wordpressPage

    if (StaticPage === null) {
        console.log('StaticPage is empty', this.props)
        // console.log('StaticPage', StaticPage)

        return null
    }

    const hasTitle = (StaticPage.slug !== 'home')

    return (
        <Layout>
            <SEO title={StaticPage.title} />

            { hasTitle && (
                <div className='heading-flex-wrapper flex justify-center'>
                    <div className='heading-container container px-6 py-12'>
                        <h1 className='page-main-heading heading text-4xl'>
                            <SlideInText
                                text={StaticPage.title}
                            />
                        </h1>
                    </div>
                </div>
            )}

            <Blocks
                blocks={StaticPage.blocks}
            />
        </Layout>
    )
  }
}

export default Page

// If new ACF fields are added you'll need to restart dev tools for graphql to pick them up
export const pageQuery = graphql`
    fragment WPPost on wordpress__PAGE {
        wordpress_id
        id
        title
        excerpt
        slug
        type
        date
    }

    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            ...WPPost
            blocks {
                ...Blocks
                attrs {
                    ...BlockAttrs
                    image {
                        ...BlockBackgroundImages
                        localFile {
                            childImageSharp {
                                fluid ( quality: 90, srcSetBreakpoints: [ 640, 768, 1024, 1366, 1600, 1920 ] ) {
                                    ...BlockImageProperties
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
