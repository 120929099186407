import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import { onceTransitionEnd } from 'web-animation-club'
import { observeElementInViewport } from 'observe-element-in-viewport'


const fadeIn = async function (rootElem, delay = 0) {

    // console.log('delay', delay, rootElem)

    await new Promise(r => setTimeout(r, delay))

    rootElem.classList.remove('opacity-0')
    rootElem.classList.add('opacity-1')
}


const FadeInWrapper = ({
    children,
    baseDelay = 200,
    threshold = [ 40 ],
    className = ''
}) => {

    const rootRef = useRef(null)

    useEffect(() => {

        // console.log('document', document)

        // the returned function, when called, stops tracking the target element in the
        // given viewport
        const unobserve = observeElementInViewport(rootRef.current, async (_, unobserve, rootElem) => {
            // On element enter viewport

            const atTopOfPage = window.scrollY < 10

            fadeIn(rootElem, (atTopOfPage ? baseDelay : 0))

            // Wait for transitions to finish
            // await onceTransitionEnd(rootRef.current)

            // Clear will-change
            // rootRef.current.style.setProperty('will-change', null)

            // Turn off this observer
            unobserve()
        }, () => {
            // On element exit viewport
        }, {
            // set viewport
            // viewport,

            // decrease viewport top by 100px
            // similar to this, modRight, modBottom and modLeft exist
            // modTop: '-100px',

            // threshold tells us when to trigger the handlers.
            // a threshold of 90 means, trigger the inHandler when atleast 90%
            // of target is visible. It triggers the outHandler when the amount of
            // visible portion of the target falls below 90%.
            // If this array has more than one value, the lowest threshold is what
            // marks the target as having left the viewport
            threshold
        })

        // Destroy/unMount
        return () => {
            // console.log('Destroying Slide in wrapper')

            // Clean up observer
            unobserve()
        }

    }, [
        rootRef,
        baseDelay,
        threshold
    ])

  return (
    <div
        ref={rootRef}
        className={[
            'fade-in-wrapper opacity-0 transform ease',
            className
        ].join(' ')}
        style={{
            // transitionDelay: `${baseDelay}ms`
        }}
    >
        { children }
    </div>
  )
}

FadeInWrapper.propTypes = {
    baseDelay: PropTypes.number,
    className: PropTypes.string,
}


export default FadeInWrapper
