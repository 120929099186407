import React from 'react'
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/vimeo'
import 'js-video-url-parser/lib/provider/youtube'

// const getVideoId = (videoUrl) => urlParser.parse(videoUrl).id

const makeEmbedUrl = function (videoUrl) {
    const { id, provider } = urlParser.parse(videoUrl)

    const embedUrl = {
        'vimeo': `https://player.vimeo.com/video/${id}?dnt=1&amp;app_id=122963`,
        'youtube': `https://www.youtube.com/embed/${id}?playsinline=1`
    }[provider]

    return {
        embedUrl,
        provider,
        id
    }
}

export default (props) => {
    // console.log('props', props)
    // { url, type, providerNameSlug, className }
    const {
        // attrs = null,
        innerHTML
    } = props

    const innerHTMLPieces = innerHTML.split(/\r?\n/g)
    const videoUrl = innerHTMLPieces[2]
    const {
        embedUrl,
        provider,
        id
    } = makeEmbedUrl(videoUrl)

    return (
        <figure className={`wp-block-embed-${provider} wp-block-embed is-type-video is-provider-${provider}`}>
            <div className='wp-block-embed__wrapper'>
                <iframe
                    title={`${provider} Video with ID ${id}`}
                    data-src={embedUrl}
                    width='640'
                    height='360'
                    frameBorder='0'
                    allow='autoplay; fullscreen'
                    allowFullScreen
                    className='lazyload'
                />
            </div>
        </figure>
    )
}
