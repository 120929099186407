import React, { useRef } from 'react'

import SlideInText from '../../typography/slide-in-text'
import ParallaxImage from '../../media/parallax-image'
import Link from '../../primitives/link'


export default (block) => {

    const {
        heading,
        text,
        url,
        image,
        className,
        // blockId,
        // blockUniqueClass
    } = block.attrs

    // const mediaImage = JSON.parse(decodeURIComponent(image))

    const rootRef = useRef(null)

    // console.log({
    //     heading,
    //     text,
    //     mediaImage,
    // })

    return (
        <div
            ref={rootRef}
            className={[
                `media-block-feature-block flex justify-center w-full lazyload`,
                className
            ].join(' ')}
        >

            <div className='block-layers relative w-full flex justify-center items-center bg-white'>

                <div className='block-content-layer w-full'>

                    <div className='w-full h-24 border-black bg-white flex justify-end'>
                        <div className='w-64 bg-crimson'></div>
                    </div>

                    <div className='w-full flex items-center justify-start relative'>
                        <div className='w-auto md:w-3/12'></div>

                        <div className='w-full md:w-8/12 bg-gray-100 md:py-12 relative md:flex'>


                            <ParallaxImage
                                image={image}
                                className={[
                                    'md:absolute top-0 left-0 block w-full h-full object-cover lazyload'
                                ].join(' ')}
                            />

                            <Link
                                to={url}
                                className='block relative bg-white h-full w-full px-12 md:px-0 md:w-6/12 py-24 md:-ml-24'
                            >
                                <h3 className='mb-2 uppercase text-crimson text-xl md:text-3xl font-bold tracking-widest'>
                                    <SlideInText text={heading} />
                                </h3>
                                <div className='text-lg md:text-xl text-gray-400'>
                                    <SlideInText
                                        text={text}
                                        baseDelay={1000}
                                    />
                                </div>
                            </Link>

                        </div>

                        <div className='w-1/12 absolute top-0 right-0 h-full bg-crimson'></div>
                    </div>

                    <div className='w-full h-24 border-black bg-white flex justify-end'>
                        <div className='w-64 bg-crimson'></div>
                    </div>

                </div>

            </div>

        </div>
    )
}
