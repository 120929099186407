import React, { useRef } from 'react'

import SlideInText from '../../typography/slide-in-text'
import ParallaxImage from '../../media/parallax-image'
import Link from '../../primitives/link'


export default (block) => {

    const {
        heading,
        text,
        url,
        image,
        className,
        // blockId,
        // blockUniqueClass
    } = block.attrs

    // const mediaImage = JSON.parse(decodeURIComponent(image))

    const rootRef = useRef(null)

    const hasHeading = (heading.length !== 0)

    // console.log({
    //     heading,
    //     text,
    //     mediaImage,
    // })

    return (
        <div
            ref={rootRef}
            className={[
                `parallax-image-block flex justify-center w-full lazyload`,
                className
            ].join(' ')}
        >

            <div className='block-layers relative w-full flex justify-center items-center bg-white'>

                <div className='block-content-layer w-full'>

                    <div className='w-full h-24 border-black bg-white flex justify-end'>
                        <div className='w-64 bg-crimson'></div>
                    </div>

                    <div className='image-row w-full flex relative'>
                        <div className='w-0 md:w-5/12'></div>

                        <div className='w-full bg-gray-100 relative'>


                            <ParallaxImage
                                image={image}
                                className={[
                                    // 'absolute top-0 left-0 block w-full h-full object-cover lazyload',
                                    'w-full lazyload'
                                ].join(' ')}
                            />


                            { hasHeading && (
                                <Link
                                    to={url}
                                    className='block md:absolute bottom-0 left-0 bg-white px-12 md:px-0 py-6 md:-ml-24'
                                >
                                    <h3 className='mb-2 uppercase text-crimson text-3xl font-bold tracking-widest'>
                                        <SlideInText text={heading} />
                                    </h3>
                                    <div className='text-lg md:text-xl text-gray-400'>
                                        <SlideInText
                                            text={text}
                                            baseDelay={1000}
                                        />
                                    </div>
                                </Link>
                            )}

                        </div>

                        <div className='w-1/12 bg-crimson'></div>
                    </div>

                    <div className='w-full h-24 border-black bg-white flex justify-end'>
                        <div className='w-64 bg-crimson'></div>
                    </div>

                </div>

            </div>

        </div>
    )
}
