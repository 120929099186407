import React from 'react'

import FadeInWrapper from '@components/animation/fade-in-wrapper'

export const getAlignClassName = function (align, defaultClassName = 'max-w-2xl w-full px-6') {

    const alignClassNames = {
        'default': defaultClassName,
        'wide': 'container px-6',
        'full': 'w-full'
    }

    // If the align type is empty or not defined
    // then return the default
    if (!align || !alignClassNames.hasOwnProperty(align)) return alignClassNames['default']

    return alignClassNames[align]
}

export const blockDelayFormula = function (block) {
    return block.attrs.blockIndex * 20
}

export default (block) => {

    // console.log('block', block)

    const {
        align,
        className = ''
    } = block.attrs

    return (
        <section className={[
            'wpg-block wpg-b_paragraph',
            className || '',
            getAlignClassName(align)
        ].join(' ')}

        >
            <FadeInWrapper
                baseDelay={blockDelayFormula(block)}
            >
                <div dangerouslySetInnerHTML={{ __html: block.innerHTML }} />
            </FadeInWrapper>
        </section>
    )
}
